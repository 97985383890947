import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/web/home/index.vue";

import Meta from "vue-meta";
import auth from "@/router/auth";

Vue.use(VueRouter);

Vue.use(Meta, {
  keyName: "metaInfo", // the component option name that vue-meta looks for meta info on.
  attribute: "data-vue-meta", // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: "data-vue-meta-server-rendered", // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: "vmid", // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/dang-ky",
    name: "web-account-register",
    component: () => import("@/views/web/account/Register.vue"),
    beforeEnter: auth.requireAuthV2,
  },

  // Module Web Item
  {
    path: "/danh-sach-san-pham",
    name: "web-items-index",
    component: () => import("@/views/web/items/index.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Module Web news
  {
    path: "/tin-tuc",
    name: "web-news",
    component: () => import("@/views/web/news/index.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Module Web news-detail
  {
    path: "/thong-tin-chi-tiet/:id",
    name: "web-news-detail",
    component: () => import("@/views/web/news/detail.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Module Web bộ sưu tập
  {
    path: "/bo-suu-tap",
    name: "web-items-index2",
    component: () => import("@/views/web/items/index-2.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Module chi tiết sản phẩm
  {
    path: "/chi-tiet-san-pham/:id",
    name: "web-items-detail",
    component: () => import("@/views/web/items/item-detail.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Module thanh toán
  {
    path: "/thanh-toan",
    name: "web-pay-index",
    component: () => import("@/views/web/pay/index.vue"),
    beforeEnter: auth.requireAuthV2,
  },
  // Module giỏ hàng
  {
    path: "/gio-hang",
    name: "web-cart-index",
    component: () => import("@/views/web/cart/index.vue"),
    beforeEnter: auth.requireAuthV2,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
