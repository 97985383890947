<template>
  <div class="web-home-index">
    <div class="image-main wow bounceInUp">
      <img src="@/static/images/home/banner-main.jpg" alt="" />
    </div>
    <div class="for-girl-1 pt-4 pt-sm-5 pb-4" v-if="show">
      <div class="container-fluid-max">
        <div class="row g-3">
          <div class="col-md-5 d-none d-sm-block">
            <div class="image-main">
              <img src="@/static/images/home/for-gril-1.jpg" alt="" />
            </div>
          </div>
          <div class="col-md-7">
            <div class="for-girl-1-title">
              <span>Dành cho nữ</span>
            </div>
            <div class="for-girl-1-list">
              <div class="row">
                <div
                  class="col-md-4 col-6"
                  v-for="(item, index) in lists"
                  :key="index.image"
                >
                  <div v-if="index < 6">
                    <div class="info" @click.stop="showDetail(item)">
                      <div
                        class="image d-flex justify-content-center align-items-center"
                      >
                        <img :src="item.image" alt="" />
                      </div>
                      <div class="d-flex flex-column">
                        <span class="name">{{ item.name }}</span>
                        <span class="price">{{ item.price | vnd }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-number-1 pt-4 pt-sm-5 pb-4 wow shake" v-if="show">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <div class="image-main">
              <img src="@/static/images/home/banner-1-1.jpg" alt="" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="image-main pt-3 pt-sm-0">
              <img src="@/static/images/home/banner-2-2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="for-girl-1 for-men-1 pt-4 pt-sm-5 pb-4" v-if="show">
      <div class="container-fluid-max">
        <div class="row g-3">
          <div class="col-md-7">
            <div class="for-girl-1-title">
              <span>Dành cho nam</span>
            </div>
            <div class="for-girl-1-list">
              <div class="row">
                <div
                  class="col-md-4 col-6"
                  v-for="(item, index) in lists"
                  :key="index.image"
                >
                  <div
                    v-if="index >= 6"
                    class="d-flex justify-content-center align-content-center"
                  >
                    <div
                      class="info d-flex flex-column"
                      @click.stop="showDetail(item)"
                    >
                      <div
                        class="image d-flex justify-content-center align-items-center"
                      >
                        <img :src="item.image" alt="" />
                      </div>
                      <div class="d-flex flex-column">
                        <span class="name">{{ item.name }}</span>
                        <span class="price">{{ item.price | vnd }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5 d-none d-sm-block">
            <div class="image-main">
              <img src="@/static/images/home/for-men-1.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner-number-2 pt-4 pt-sm-5 pb-4" v-if="show">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="image-main wow swing">
              <img src="@/static/images/home/new-collection-2.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hot-sale pt-4 pt-sm-5 pb-4">
      <div class="container">
        <div class="row">
          <div class="col-md-4 title-1">
            <div class="title text-center">
              <span>Gợi ý</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="title text-center">
              <span>Bán chạy</span>
            </div>
          </div>
          <div class="col-md-4 title-1">
            <div class="title text-center">
              <span>Giảm giá</span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4" v-for="(item, index) in lists" :key="index">
            <div v-if="index < 9">
              <div class="component d-flex mt-5" @click.stop="showDetail(item)">
                <div
                  class="image d-flex justify-content-center align-items-center"
                >
                  <img :src="item.image" alt="" />
                </div>
                <div class="info d-flex flex-column">
                  <span class="name">{{ item.name }}</span>
                  <span class="price">{{ item.price | vnd }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: [],
  },
  name: "WebHomeIndex",
  metaInfo() {
    return {
      title: "Fashionbiz - Kinh đô thời trang",
    };
  },
  components: {},
  data() {
    return {
      show: true,
      show2: true,
      lists: [
        {
          id: 9,
          image: "https://cf.shopee.vn/file/cf32a568ca716117f1e1d5c0cfdc6e79",
          name: "Áo sơ mi dài tay",
          size: "S",
          price: "530000",
          quantity: 1,
        },
        {
          id: 10,
          image:
            "https://file.hstatic.net/1000197303/file/set-do-don-gian-thoi-thuong_4186e3b2b97542bd939a65f3fbcd2f7e.jpg",
          name: "Chân váy xẻ tà",
          price: "630000",
          size: "S",
          quantity: 1,
        },
        {
          id: 11,
          image:
            "https://traffic-edge48.cdn.vncdn.io/nvn/ncdn/store/3379/ps/20190410/zzzgst006_800x800.jpg",
          name: "Bộ quần áo nữ",
          price: "780000",
          size: "S",
          quantity: 1,
        },
        {
          id: 12,
          image:
            "https://salt.tikicdn.com/ts/product/9e/b8/bd/64ac37aed09ca606d85b422a0149c8c8.jpg",
          name: "Set quần áo nữ",
          price: "890000",
          size: "S",
          quantity: 1,
        },
        {
          id: 13,
          image:
            "https://sakurafashion.vn/upload/a/2357-t-shop-thoi-trang-vay-dam-5019.jpg",
          name: "Váy trắng",
          price: "670000",
          size: "S",
          quantity: 1,
        },
        {
          id: 14,
          image:
            "https://dongnaireview.com/wp-content/uploads/2020/10/nong-stor.jpg",
          name: "Quần short nữ",
          price: "450000",
          size: "S",
          quantity: 1,
        },
        // Nam
        {
          id: 15,
          image:
            "https://salt.tikicdn.com/ts/tmp/32/ed/23/bb8319aff2aee8ea6b6631ff0ab02ac0.jpg",
          name: "Bộ quần áo nam thể thao",
          price: "50000",
          size: "S",
          quantity: 1,
        },
        {
          id: 16,
          image:
            "https://www.hnf.vn/Uploads/Image/0-1-bang-gia-mua-si-quan-ao-nam/bang-gia-mua-si-quan-ao-nam-cao-cap-3.jpg",
          name: "Áo cộc tay nam",
          price: "450000",
          size: "S",
          quantity: 1,
        },
        {
          id: 17,
          image: "https://topchuan.com/wp-content/uploads/2019/11/1-195.jpg",
          name: "Áo sơ mi dài tay nam",
          price: "450000",
          size: "S",
          quantity: 1,
        },
        {
          id: 18,
          image:
            "https://cdn.sudospaces.com/website/topz.vn/home/topz/public_html/2019/12/h2t-314177.jpg",
          name: "Áo jean nam",
          price: "500000",
          size: "S",
          quantity: 1,
        },
        {
          id: 19,
          image:
            "https://dongphuchaianh.vn/wp-content/uploads/2022/03/ao-polo-quan-tay-ngau.jpg",
          name: "Áo polo cộc tay nam",
          price: "450000",
          size: "S",
          quantity: 1,
        },
        {
          id: 20,
          image:
            "https://image.voso.vn/users/vosoimage/images/1a78f475cc3186ff1fd4faf2aaf61fc2?t%5B0%5D=compress%3Alevel%3D100&accessToken=24b29b25da8870ecc2f21863cc7bba16318199ddc1131f70cbb5fab8c95a2f83",
          name: "Bộ quần áo HOODIE nam",
          price: "650000",
          size: "S",
          quantity: 1,
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.PushLocalStorage();
    this.addItems();
    this.saveDatas();
  },
  methods: {
    showDetail(item) {
      this.$router.push({
        name: "web-items-detail",
        params: { id: item.id },
      });
    },
    PushLocalStorage() {
      if (localStorage.getItem("listsItem")) {
        try {
          this.listsItem = JSON.parse(localStorage.getItem("listsItem"));
        } catch (e) {
          localStorage.removeItem("listsItem");
        }
      }
    },
    addItems() {
      // ensure they actually typed something
      if (!this.listsItem) {
        return;
      }
      this.listsItem.push(this.lists);
      this.saveDatas();
    },
    saveDatas() {
      const parsed = JSON.stringify(this.lists);
      localStorage.setItem("lists", parsed);
    },
  },
};
</script>

<style lang="css" scoped>
.for-girl-1-list {
  padding-left: 2em;
}
.for-girl-1-list .image {
  background-color: #f9f9f9;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  width: 100%;
  margin-right: 15px;
}
.for-girl-1-list img {
  width: 100%;
  object-fit: cover;
  height: 200px;
}
.info span.name {
  font-weight: 500;
  font-size: 13px;
  line-height: 13px;
  padding-top: 18px;
}
.info {
  width: 193px;
  min-height: 250px;
  cursor: pointer;
  border: 1px solid #fff;
  margin-bottom: 3em;
}
.info:hover {
  opacity: 0.8;
  transition: all 0.3s;
}
.info span.price {
  font-size: 12px;
  color: #858484;
  padding-top: 7px;
}
.web-home-index {
  min-height: 2000px;
  margin-top: 10px;
}
.image-main img {
  width: 100%;
  object-fit: cover;
}

.for-girl-1 {
  min-height: 500px;
}
.for-girl-1-title {
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 24px;
  margin-left: 15px;
  margin-bottom: 38px;
  margin-right: 60px;
}
@media screen and (max-width: 768px) {
  .for-girl-1-title {
    margin-right: 75px;
  }
  .for-girl-1-list {
    padding: 10px;
  }
  .for-girl-1-list img {
    max-width: 100%;
  }
  .title-1 {
    display: none;
  }
}
.for-men-1 .for-girl-1-title {
  margin-right: 15px !important;
  text-align: right;
}
@media screen and (min-width: 768px) {
  .for-men-1 .for-girl-1-title {
    margin-right: 0px;
    margin-left: 75px;
  }
  .for-men-1 .for-girl-1-list {
    padding-right: 0px;
    padding-left: 35px;
  }
}

.component {
  cursor: pointer;
  border: 1px solid #fff;
  margin-bottom: 25px;
}
.component:hover {
  opacity: 0.8;
  transition: all 0.3s;
}
.hot-sale .title span {
  font-size: 22px;
  font-weight: bold;
  border-bottom: 1px solid #adadad;
  display: inline-block;
  padding: 0px 20px;
  padding-bottom: 5px;
}
.hot-sale .list {
  padding-top: 45px;
}
.hot-sale img {
  width: 100%;
  object-fit: cover;
  height: 250px;
}
.hot-sale .image {
  background-color: #f9f9f9;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.25));
  width: 80%;
  margin-right: 15px;
}
.hot-sale .info {
  padding: 5px 0px 10px 5px;
  min-height: 90px;
  border: none;
}
</style>
