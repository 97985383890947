Skip to content Search or jump to… Pull requests Issues Marketplace Explore
@dkien111 nguyenxuantuan / fashion-web Private Code Issues Pull requests Actions
Projects Security Insights fashion-web/src/views/layout/header.vue @dkien111
dkien111 up Latest commit b8247c7 18 days ago History 2 contributors
@nnthuc2402@dkien111 273 lines (269 sloc) 6.94 KB

<template>
  <div class="fashionbiz-header">
    <div class="container">
      <div
        class="sub-menu d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center"
      >
        <div class="download-group">
          <a href="#">Tải ứng dụng</a>
          <a href="#">Kết nối</a>
        </div>
        <div class="action-group d-none d-sm-block">
          <img src="@/static/icon/header/menu-sub/noti.svg" />
          <a href="#" class="item">Thông báo</a>
          <img src="@/static/icon/header/menu-sub/support.svg" />
          <a href="#" class="item">Hỗ trợ</a>
          <img src="@/static/icon/header/menu-sub/language.svg" />
          <a href="#" class="item">Tiếng Việt</a>
          <span class="line-1"></span>
          <span @click="formLogin()" class="item">Đăng nhập</span>
          <span class="line-2">|</span>
          <span
            class="item"
            :class="[full_path == '/dang-ky' ? 'active' : '']"
            @click.stop="nextRoute('web-account-register', '/dang-ky')"
            >Đăng ký</span
          >
        </div>
      </div>
      <div class="main-menu d-flex justify-content-between align-items-center">
        <div class="logo">
          <a href="/">
            <img src="@/static/images/logo/thuongdovn.svg" />
          </a>
        </div>
        <div class="menu d-none d-sm-block">
          <span
            class="item"
            :class="[full_path == '/danh-sach-san-pham' ? 'active' : '']"
            @click.stop="nextRoute('web-items-index', '/danh-sach-san-pham')"
            >Sản phẩm mới nhất</span
          >
          <img src="@/static/icon/header/menu-main/down.svg" />
          <span class="item">Đầm</span>
          <img src="@/static/icon/header/menu-main/down.svg" />
          <span class="item">Áo</span>
          <img src="@/static/icon/header/menu-main/down.svg" />
          <span class="item">Quần</span>
          <img src="@/static/icon/header/menu-main/down.svg" />
          <span
            class="item"
            :class="[full_path == '/bo-suu-tap' ? 'active' : '']"
            @click.stop="nextRoute('web-items-index2', '/bo-suu-tap')"
            >Bộ sưu tập</span
          >
          <img src="@/static/icon/header/menu-main/down.svg" />
          <span class="item">Kiến thức</span>
          <span
            class="item"
            :class="[full_path == '/tin-tuc' ? 'active' : '']"
            @click.stop="nextRoute('web-news', '/tin-tuc')"
            >Tin tức</span
          >
        </div>
        <div class="cart menu">
          <img class="search" src="@/static/icon/header/menu-main/search.svg" />
          <img src="@/static/icon/header/menu-main/like.svg" />
          <!-- <img src="@/static/icon/header/menu-main/cart.svg" /> -->
          <span
            class="item"
            :class="[full_path == '/gio-hang' ? 'active' : '']"
            @click.stop="nextRoute('web-cart-index', '/gio-hang')"
            ><i class="fa-solid fa-cart-arrow-down"></i
          ></span>
        </div>
      </div>
    </div>
    <el-form
      class="login-form"
      :model="userInfo"
      ref="createModel"
      :rules="rules"
    >
      <el-dialog title="Đăng nhập" :visible.sync="dialogVisible" width="30%">
        <div class="title pb-4">
          <span>Hãy đăng nhập bằng tên hoặc email</span>
        </div>
        <el-form-item prop="name" label="">
          <el-input
            class="input-login"
            placeholder="Tên hoặc email"
            v-model="userInfo.name"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" label="">
          <el-input
            class="input-login"
            placeholder="Mật khẩu"
            show-password
            v-model="userInfo.password"
          ></el-input>
        </el-form-item>
        <div class="title-forgot">
          <span>Quên mật khẩu ?</span>
        </div>
        <el-form-item>
          <el-button
            class="mt-3"
            type="success"
            @click.stop="submitForm('createModel')"
            >Đăng nhập</el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button @click="toRegister()">Tạo tài khoản</el-button>
        </el-form-item>
      </el-dialog>
    </el-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "WebLayoutHeader",
  data() {
    return {
      dialogVisible: false,
      userInfo: {
        name: "",
        password: "",
      },
      rules: {
        name: [
          { required: true, message: "Vui lòng nhập đầy đủ thông tin" },
          {
            min: 4,
            max: 30,
            message: "Email có chiều dài từ 4 đến 30 kí tự",
          },
        ],
        password: [
          { required: true, message: "Vui lòng nhập password" },
          {
            min: 4,
            max: 30,
            message: "Password có chiều dài từ 4 đến 30 kí tự",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["path", "full_path"]),
  },
  methods: {
    nextRoute(name, full_path) {
      if (this.full_path !== full_path) {
        this.$router.push({ name: name });
      }
    },
    formLogin() {
      this.dialogVisible = true;
    },
    toRegister() {
      let a = false;
      this.$router.push({ name: "web-account-register" });
      this.dialogVisible = a;
    },
    submitForm(forname) {
      this.$refs[forname].validate((valid) => {
        if (valid) {
          this.dialogVisible = false;
          this.$notify({
            title: "Thành công",
            message: "Bạn đã đăng nhâp thành công",
            type: "success",
          });
        } else {
          this.$notify({
            title: "Đăng nhập thất bại",
            message: "Vui lòng kiểm tra lại thông tin",
            type: "warning",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="css" scoped>
.fashionbiz-header a {
  color: inherit;
  text-decoration: none;
}
.fashionbiz-header a:hover {
  color: #ed1d24;
}
.sub-menu {
  padding: 10px 15px 3px 15px;
}
.sub-menu .download-group a {
  margin-right: 10px;
  font-size: 14px;
}
.sub-menu .action-group .item {
  font-size: 14px;
}
.sub-menu .action-group .item.active {
  color: #ed1d24;
}
.sub-menu .action-group .item:hover {
  color: #ed1d24;
  cursor: pointer;
}
.sub-menu .action-group img {
  width: 15px;
  margin-right: 5px;
  margin-left: 16px;
}
.sub-menu .action-group .line-1 {
  width: 30px;
  display: inline-block;
}
.sub-menu .action-group .line-2 {
  display: inline-block;
  padding: 0px 8px;
}
.main-menu {
  padding: 5px 0px;
}
.main-menu .logo img {
  height: 65px;
}
@media screen and (min-width: 768px) {
  .main-menu .logo img {
    height: 85px;
    min-width: 220px;
  }
}
.main-menu .menu .item {
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 15px;
  margin-right: 3px;
  font-size: 14px;
  cursor: pointer;
}
.main-menu .menu .item:hover {
  color: #ed1d24;
}
.main-menu .menu .item.active {
  color: #ed1d24;
}
.main-menu .menu img {
  min-width: 9px;
}
.main-menu .cart {
  margin-left: 15px;
}
.main-menu .cart img {
  width: 15px;
  margin-left: 15px;
  cursor: pointer;
}
.main-menu .cart img.search {
  width: 18px;
}
</style>
<style type="text/css">
.login-form .el-dialog {
  background: url(@/static/images/home/vector.png) no-repeat;
  background-size: 100%;
  box-shadow: none;
  padding-top: 2em;
  position: absolute;
  right: 1em;
  top: -4em;
}
.login-form .el-dialog__header {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}
.el-dialog__body {
  padding: 20px 20px !important;
}
.el-dialog__body .title {
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #c4c4c4;
}
.input-login {
  border: none;
  border-radius: 7px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #c4c4c4;
}
.title-forgot {
  padding: 0.5em 0;
  text-align: right;
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #c4c4c4;
}
.login-form .el-button {
  width: 100%;
}
</style>
