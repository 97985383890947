<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/views/layout/header";
import Footer from "@/views/layout/footer";
export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
};
</script>
