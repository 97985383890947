<template>
  <div class="fashionbiz-footer">
    <div class="logo text-center">
      <img src="@/static/images/logo/thuongdo_footer.svg" />
    </div>
    <div class="group-4">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <div class="title">
              <span>Công ty cổ phần quốc tế</span>
            </div>
            <div class="list-link d-flex flex-column">
              <span>Số 176 Trường Chinh, Thanh Xuân, Hà Nội.</span>
              <span>1900 6825</span>
              <span>info@thuogndo.vn</span>
            </div>
            <div class="list-social pt-1">
              <img src="@/static/icon/footer/social/fb.svg" />
              <img src="@/static/icon/footer/social/tito.svg" />
              <img src="@/static/icon/footer/social/inta.svg" />
              <img src="@/static/icon/footer/social/youtube.svg" />
              <img src="@/static/icon/footer/social/tictoc.svg" />
            </div>
            <div class="list-bct pt-2">
              <img src="@/static/icon/footer/social/bct.svg" />
            </div>
          </div>
          <div class="col-md-3">
            <div class="title">
              <span>Chính sách</span>
            </div>
            <div class="list-link d-flex flex-column">
              <span>Điều khoản sử dụng</span>
              <span>Hướng dẫn thanh toán</span>
              <span>Hướng dẫn mua hàng</span>
              <span>Chính sách vận chuyển</span>
              <span>Chính sách đổi trả</span>
              <span>Chính sách bán hàng và bảo hành</span>
              <span>Chính sách bảo vệ thông tin khách hàng</span>
              <span>Quyền lợi sinh nhật khách hàng</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="title">
              <span>Về công ty</span>
            </div>
            <div class="list-link d-flex flex-column">
              <span>Giới thiệu</span>
              <span>Tin tức</span>
              <span>Tuyển dụng</span>
              <span>Liên hệ</span>
              <span>Hợp tác kinh doanh</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="title">
              <span>Download Your App</span>
            </div>
            <div class="list-download">
              <img src="@/static/icon/footer/download/apple.svg" class="pe-2" />
              <img src="@/static/icon/footer/download/android.svg" />
            </div>
            <div class="title pt-4">
              <span>Phương thức thanh toán</span>
            </div>
            <div class="list-payment pt-2">
              <img class="pe-2" src="@/static/icon/footer/payment/ame.svg" />
              <img class="pe-2" src="@/static/icon/footer/payment/visa.svg" />
              <img class="pe-2" src="@/static/icon/footer/payment/paypal.svg" />
              <img class="pe-2" src="@/static/icon/footer/payment/btc.svg" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebLayoutFooter",
  data() {
    return {};
  },
};
</script>

<style lang="css" scoped>
.fashionbiz-footer {
  min-height: 300px;
  background-color: #f3f3f3;
  padding-bottom: 67px;
}
.fashionbiz-footer a {
  color: inherit;
  text-decoration: none;
}
.fashionbiz-footer a:hover {
  color: #ed1d24;
}

.fashionbiz-footer .logo {
  padding: 15px 0px 30px 0px;
}
.fashionbiz-footer .logo img {
  height: 85px;
}

.group-4 .title {
  text-transform: uppercase;
  color: #858484;
  font-size: 16px;
  min-height: 40px;
  margin-top: 20px;
}
@media screen and (min-width: 768px) {
  .group-4 .title {
    min-height: 50px;
    margin-top: 0px;
  }
}
.group-4 .list-link span {
  color: #858484;
  font-size: 13px;
  margin-bottom: 8px;
  cursor: pointer;
  letter-spacing: 1px;
  font-weight: 300;
}
.group-4 .list-link span:hover {
  color: #ed1d24;
}
</style>
